import React, { Component} from 'react';
import { Container, Col, Row, Card} from 'react-bootstrap';
import Logo from '../assets/logo.svg'

class PageNotFound extends Component {
    render() {
        return(
            <>
                <Container fluid className="bg-light py-5vh">
                    <Row className="mvh-90 align-items-center justify-content-center">
                        <Col xl={4} lg={5} md={8}>
                            <Card>
                                <Card.Body className="p-50">
                                    <img src={Logo} alt="Cart Rescue" className="mb-30 h-40" />

                                    <Card.Title>404 - Not found</Card.Title>
                                    <Card.Subtitle className="mb-30 text-secondary">The page you are looking for is not found!</Card.Subtitle>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

}

export default PageNotFound;