import React, { Component} from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Col, Row, Card} from 'react-bootstrap';
import Logo from '../assets/logo.svg'

class Redirect extends Component {
    render() {
        return(
            <>
                <Container fluid className="bg-light py-5vh">
                    <Row className="mvh-90 align-items-center justify-content-center">
                        <Col xl={4} lg={5} md={8}>
                            <Card>
                                <Card.Body className="p-50">
                                    <img src={Logo} alt="Cart Rescue" className="mb-30 h-40" />

                                    <div className='d-grid gap-2'>
                                            <NavLink to="/agent" className="mb-20 btn btn-danger" >
                                                Continue to agent panel
                                            </NavLink>
                                    </div>

                                    <div className='d-grid gap-2'>
                                            <NavLink to="/admin" className="mb-20 btn btn-warning" >
                                                Continue to admin panel
                                            </NavLink>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

}

export default Redirect;