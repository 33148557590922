import React from 'react';
import { NavLink } from 'react-router-dom';
import  {ChatOutlined, ShoppingCartOutlined, PowerSettingsNewOutlined}  from '@material-ui/icons';

const AgentPanel = ({children}) =>{
    return(
        <div id="wrapper">
            <div className="tabbar bg-primary">
                <ul>
                    <li><NavLink exact to="/"><ChatOutlined /></NavLink></li>
                    {/*<li><NavLink exact to="/queue"><ShoppingCartOutlined /></NavLink></li>*/}
                    <li><NavLink exact to="/logout"><PowerSettingsNewOutlined/></NavLink></li>
                </ul>
            </div>
            <section className="content">
                {children}
            </section>
        </div>
    );
}

export default AgentPanel;